exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cv-js": () => import("./../../../src/pages/cv.js" /* webpackChunkName: "component---src-pages-cv-js" */),
  "component---src-pages-grafik-js": () => import("./../../../src/pages/grafik.js" /* webpackChunkName: "component---src-pages-grafik-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projekt-js": () => import("./../../../src/pages/projekt.js" /* webpackChunkName: "component---src-pages-projekt-js" */),
  "component---src-templates-instagram-gallery-js": () => import("./../../../src/templates/instagramGallery.js" /* webpackChunkName: "component---src-templates-instagram-gallery-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */)
}

